import { translate } from "./api";
import he from "he";

class Utils {
  getLanguage(dict, dashboardLanguage) {
    if (typeof dict === "string" || dict === undefined) {
      return dict;
    }

    if (dashboardLanguage) {
      if (dict.hasOwnProperty(dashboardLanguage)) {
        return dict[dashboardLanguage];
      }
    } else {
      const lgs = navigator.languages.map((x) => x.substring(0, 2));

      for (const lg of lgs) {
        if (dict.hasOwnProperty(lg)) {
          return dict[lg];
        }
      }
    }

    return dict[Object.keys(dict)[0]];
  }

  runAlloBrain(process_id, data) {
    return new Promise((res, rej) =>
      fetch(
        "https://40u6kvuqd7.execute-api.eu-west-3.amazonaws.com/prod" +
          "/run/" +
          process_id,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      )
        .then((response) => {
          if (response.status === 200) {
            return response.json().then((x) => {
              res(JSON.parse(x["body"].replaceAll("[[appostrophe]]", "'")));
            });
          } else {
            response.json().then(rej);
          }
        })
        .catch((e) => {
          rej(e);
        })
    );
  }

  getUrlVars() {
    var vars = {};
    window.location.href.replace(
      /[#&]+([^=&]+)=([^&]*)/gi,
      function (m, key, value) {
        vars[key] = value;
      }
    );
    return vars;
  }

  getToken() {
    return this.getUrlVars()["access_token"] || localStorage.getItem("token");
  }

  isValidJson(json) {
    try {
      JSON.parse(json);
      return true;
    } catch (e) {
      return false;
    }
  }

  default_color_palette = [
    "#277DA1",
    "#F9C74F",
    "#577590",
    "#F9844A",
    "#4D908E",
    "#F8961E",
    "#43AA8B",
    "#F3722C",
    "#a1d685",
    "#fc7878",
  ];

  color_conversion = {
    blue: "#277DA1",
    green: "#a1d685",
    red: "#fc7878",
    orange: "#ffbd74",
    grey: "#577590",
    yellow: "#F9C74F",
    neutral: "#e7e7e7",
    transparent: "rgba(0,0,0,0)",
  };

  getColor(color) {
    if (this.color_conversion.hasOwnProperty(color)) {
      return this.color_conversion[color];
    }
    return color;
  }

  async translateTextIfNeeded(text, language, isTranslate) {
    if (text.trim().length === 0 || !isTranslate) {
      return text;
    } else {
      let langKey = language.substring(0, 2);
      let textDict = {
        [langKey]: text,
      };

      let translateText = await translate(textDict);

      return he.decode(JSON.parse(translateText.data)[0][langKey]);
    }
  }
}

let utils = new Utils();

export default utils;
