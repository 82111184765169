import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

export default function LoadingSpin({ size }) {
  return (
    <Spin
      indicator={
        <LoadingOutlined
          style={{
            fontSize: { size },
          }}
          spin
        />
      }
    />
  );
}
