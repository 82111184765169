import AWS from "aws-sdk";

function getUrlVars() {
  const vars = {};
  window.location.href.replace(
    /[#&]+([^=&]+)=([^&]*)/gi,
    function (m, key, value) {
      vars[key] = value;
    }
  );
  return vars;
}

AWS.config.region = "eu-west-2"; // Région
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: "eu-west-2:13d810d5-435e-453b-8cd4-c3ea362d4d89",
  Logins: {
    "cognito-idp.eu-west-2.amazonaws.com/eu-west-2_Bm4KP9CbL":
      getUrlVars()["id_token"],
  },
});

function authentificate() {
  return new Promise((res) =>
    AWS.config.credentials.refresh((a) => {
      const b = new AWS.CognitoIdentityServiceProvider();
      b.getUser(
        {
          AccessToken:
            getUrlVars()["access_token"] || localStorage.getItem("token"),
        },
        function (err, data) {
          if (err) {
            window.location.replace(
              "https://auth.alloreview.com/login?client_id=193pbgh938lhpgjf5efeldifc5&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=" +
                window.location.origin +
                "&state=path=" +
                window.location.pathname +
                "&" +
                getUrlVars()["state"]
            );
          } else {
            if (getUrlVars()["access_token"] && getUrlVars()["id_token"]) {
              localStorage.setItem("token", getUrlVars()["access_token"]);
              localStorage.setItem("id_token", getUrlVars()["id_token"]);
              const url_params = getUrlVars();
              delete url_params["access_token"];
              delete url_params["id_token"];
              delete url_params["expires_in"];
              delete url_params["token_type"];
              const url_params_string = Object.keys(url_params)
                .map(function (key) {
                  return key + "=" + url_params[key];
                })
                .join("&");
              window.history.pushState(
                {},
                "",
                window.location.href.split("#")[0] + "#" + url_params_string
              );
            }
            window.tracker.setUserID(data["Username"]);
            res(true);
          }
        }
      );
    })
  );
}

export default authentificate;
